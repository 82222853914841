// helper functions here to reduce complexity of the main composable, reported by SQ
const shouldUnsubscribe = (isGuest, smsNumber, optIn) => !isGuest && smsNumber && optIn

const loadProfile = async (store, isGuest) => {
  if (import.meta.client && !store.profile && !isGuest) await store.get()
}

const updateProfile = async (store, isGuest) => {
  if (import.meta.client && !isGuest) await store.get()
}

export default () => {
  const { interestOnlyForSubscription } = useAppConfig().pages.profile
  const { consumer } = useApi()
  const auth = useAuthStore()
  const isGuest = computed(() => !auth.loggedIn)

  const store = useProfileStore()
  loadProfile(store, isGuest.value) // conditionally loads the profile if it's not already loaded

  const smsNumber = computed(() => store.subscriptions.sms?.recipient)
  const smsSubscription = computed(() => store.subscriptions.sms)
  const newsletterSubscribed = computed(() => !!store.profile?.subscriptions?.find(({ type }) => type === 'Newsletter')?.optin)
  const loyaltyNewsletterSubscribed = computed(() => !!store.profile?.subscriptions?.find(({ type }) => type === 'LoyaltyNewsletter')?.optin)

  async function sms(phone: string, phoneCode: string, optIn = true) {
    const serverDate = utcIsoDate(await getServerDate())

    let payload: any = {
      type: 'Newsletter',
      optin: optIn,
      doubleOptin: false,
      channel: 'sms',
      // the user can change the number in the form if not replied 'Y' to the SMS,
      // so we need to ensure we unsubscribe the previous subscribed number, not the field input
      recipient: !optIn ? smsNumber.value : formatE164phone(phone, phoneCode),
      category: 'subscription',
      country: useCountryCode(),
      region: useRegion(),
      [optIn ? 'optinDate' : 'optoutDate']: serverDate
    }

    if (isGuest.value) {
      payload = {
        consumerProfile: {
          consumerDetails: {}, // this empty object is required by the API, but should be removed: https://digital.vfc.com/jira/browse/GLOBAL15-49367
          subscriptions: [payload]
        }
      }
    }

    // if a registered user - has a previous subscription - and is opting in,
    // we need to unsubscribe first
    if (shouldUnsubscribe(isGuest.value, smsNumber.value, optIn)) {
      await consumer.$updateSmsSubscription({
        ...payload,
        optin: false,
        recipient: smsNumber.value,
        optoutDate: serverDate
      })
    }

    if (isGuest.value)
      await consumer.$createSmsSubscription(payload)
    else
      await consumer.$updateSmsSubscription(payload)

    // for registered users, reload the profile
    await updateProfile(store, isGuest.value)
  }

  async function newsletter(
    {
      optIn = true,
      loyaltyEnroll = undefined,
      loyaltyOptIn = undefined,
      firstName = '',
      lastName = '',
      email = '',
      birthDate = '',
      interests = '',
      gender,
      acquisitionType = ''
    }: {
      optIn?: boolean
      loyaltyEnroll?: boolean
      loyaltyOptIn?: boolean
      firstName?: string
      lastName?: string
      email?: string
      birthDate?: string
      interests?: string
      gender?: 'M' | 'F'
      acquisitionType?: string
    }
  ) {
    const serverDate = utcIsoDate(await getServerDate())
    const region = useRegion()

    const payload: any = {
      consumerDetails: {
        firstName: firstName || undefined,
        lastName: lastName || undefined,
        email: email || undefined,
        birthDate: birthDate || undefined,
        gender: gender || undefined,
      },
      enrollments: loyaltyEnroll ? [{ type: 'Loyalty', optin: true, doubleOptin: true, country: useCountryCode() }] : undefined,
      subscriptions: [{
        type: 'Newsletter',
        channel: 'email',
        optin: optIn,
        doubleOptin: optIn,
        country: useCountryCode(),
        region,
        [!optIn ? 'optoutDate' : 'optinDate']: serverDate
      }]
    }

    if (optIn && interests) payload.preferences = { interests }

    // need to remove the interests if the user is opting out in the required locale
    if (!optIn && interestOnlyForSubscription?.requiredLocales.includes(useLocale()))
      payload.preferences = { interests: '' }

    if (loyaltyOptIn !== undefined) {
      payload.subscriptions.push({
        type: 'LoyaltyNewsletter',
        optin: loyaltyOptIn,
        doubleOptin: loyaltyOptIn,
        region,
        channel: 'email',
        [!loyaltyOptIn ? 'optoutDate' : 'optinDate']: serverDate
      })
    }
    const body = { consumerProfile: payload }
    if (acquisitionType)
      payload.consumerDetails.source = { acquisitionType }

    if (email)
      await consumer.$createProfile(body)
    else
      await consumer.$updateProfile(body)

    // for registered users, reload the profile
    await updateProfile(store, isGuest.value)
  }
  return {
    smsNumber,
    smsSubscription,
    sms,
    newsletterSubscribed,
    loyaltyNewsletterSubscribed,
    newsletter
  }
}
